<template>
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center">

      <v-col cols="12" sm="8" md="6" lg="4">
        <v-form lazy-validation ref="form">
          <!--form header -->
          <div class="text-left">
            <h3>Passwort vergessen</h3>
            <p class="text-muted">
              Bitte geben Sie Ihren Benutzernamen ein. Wenn ein Benutzerkonto gefunden wurde, erhalten Sie eine E-Mail mit Ihrem neuen Passwort.
            </p>
          </div>
          <!--form input -->
          <div>
            <v-text-field v-model="username" :disabled="loading" :rules="[v => !!v]" prepend-inner-icon="mdi-account"
                          outlined
                          label="Benutzername"/>
            <v-btn @click="send" :loading="loading" :disabled="loading" color="primary" class="mx-auto" elevation="0" block x-large>
              Passwort anfordern
            </v-btn>
            <!--back to login -->
            <p class="mt-3">Passwort erhalten? <a href="/">Zum Login</a></p>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  name: "forgotPassword",
  data: () => ({
    loading: false,
    username: ""
  }),
  methods: {
    send() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.$axios.post(ENDPOINTS.CANTEEN.USER.AUTH.PASSWORD.RESET, {
        username: this.username
      }).then(() => {
        this.$store.commit('alert/show', {
          message: 'Neues Passwort wurde angefordert. Bitte überprüfen Sie Ihr E-Mail Postfach.',
          color: 'success',
          visible: true
        })

        this.$refs.form.reset();
      }).catch(() => {
        this.$store.commit('alert/show', {
          message: 'Error',
          color: 'error',
          visible: true
        })
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
